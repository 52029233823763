import { Link } from 'gatsby';
import React, { useState } from 'react';

import { formateURLLink, getEpisodeThumbnail } from '../../utils/watch';
import { getVideoUrl } from '../../utils/functions';
import iconPlayAlt from '../../images/icon-play-alt.svg';
import iconPlayBlk from '../../images/icon-play-blk.svg';

export default function WatchTopItem({ episodeDetail, isCategoryPage, languageCode = 'en' }) {
  const { title, videoDuration, relatedArticles, youtubeId, thumbnailImage } = episodeDetail?.data;
  const [showVideo, setShowVideo] = useState(false);
  const thumbnailURL = getEpisodeThumbnail(episodeDetail?.data);
  const videoUrl = getVideoUrl(youtubeId[0]?.text || '', languageCode);

  const formattedURL = formateURLLink(episodeDetail?.uid || '');
  const watchEpisodeURLLink = `/video_episodes/${formattedURL}`;

  return (
    <div className="carousel pd-x-sm la-pn slideshow-container">
      <div className="carousel-stage">
        <div className="carousel-item carousel-video media ratio_21-9">
          <iframe
            width="560"
            height="315"
            src={`${videoUrl}${showVideo ? '&autoplay=1&rel=0' : ''}`}
            style={{ display: showVideo ? 'inherit' : 'none' }}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {!showVideo && (
            <>
              <div className="m_overlay-gradient"></div>
              <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${thumbnailURL})` }}></div>
              {isCategoryPage ? (
                <Link to={watchEpisodeURLLink} className="media-content">
                  <h3 className="media-title">{title[0]?.text}</h3>
                  <div className="btn btn-watch-play btn-transparent">
                    <img src={iconPlayAlt} alt="Icon Play" className="icon-play-alt" />
                    <span>Watch</span>
                  </div>
                </Link>
              ) : (
                <div className="media-content">
                  <div className="btn btn-sm btn-primary-ii btn-play-icon" onClick={() => setShowVideo(true)}>
                    <img src={iconPlayBlk} alt="Icon Play" />
                  </div>
                  <h3 className="media-title">{title[0]?.text}</h3>
                  {/* <span className="media-category">Series <span className="m_cat_highlight">Season 1</span></span> */}
                </div>
              )}
              {!!videoDuration.length && (
                <div className="m_run-time m_run-time-outer">
                  <span>{videoDuration[0].text}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
