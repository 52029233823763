import React from 'react';
import { Link } from 'gatsby';

import { formateURLLink } from '../../utils/watch';
import { titleCase } from '../../utils/title-case';
import iconPlayAlt from '../../images/icon-play-alt.svg';
import { getVideoThumbnail } from '../../utils/functions';

const getThumbnailURL = (data) => {
  if (data?.image?.fluid) {
    return data.image.fluid.src;
  }

  if (data?.backgroundImage?.url) {
    return data.backgroundImage.url;
  }

  if (data?.thumbnail_image?.url) {
    return data.thumbnail_image.url;
  }

  const [videoId] = data?.youtubeId || data?.youtube_id || [];

  const thumbnailUrl = !!data?.thumbnailImage?.url ? data?.thumbnailImage.url : getVideoThumbnail(videoId?.text || '');

  return thumbnailUrl;
};

const getTitle = (data) => {
  if (data?.placeName) {
    return data?.placeName[0]?.text;
  }

  if (!Array.isArray(data?.title)) {
    return data?.title?.text;
  }

  return data?.title[0].text;
};

export default function WatchItem({
  type,
  data: { data, uid },
  showPlayIcon,
  className,
  categoryName,
  titleColor,
  showTitleInBox,
}) {
  const thumbnailUrl = getThumbnailURL(data);
  const formattedURL = formateURLLink(uid);

  let episodeItemURL = '';
  if (['people', 'places'].includes(categoryName)) {
    episodeItemURL = `/${categoryName}/${formattedURL}`;
  } else {
    episodeItemURL = `/video_episodes/${formattedURL}`;
  }

  const title = getTitle(data);

  return (
    <div className={className ? className : 'watch_item-list'}>
      <Link
        className={`watch_item-figure media ${type === 'landscape' ? 'ratio_16-9' : 'ratio_9-16'}`}
        to={episodeItemURL}
      >
        <div className="m_overlay" />
        <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>
        {showPlayIcon && (
          <div className="media-content">
            {showTitleInBox && (
              <p className="related-content-item-title" style={{ color: titleColor }}>
                {title}
              </p>
            )}
            <div className="btn btn-watch-play btn-transparent">
              <img src={iconPlayAlt} alt="Icon Play" className="icon-play-alt" />
              <span>Watch</span>
            </div>
          </div>
        )}
        {!!data?.videoDuration?.length && (
          <div className="m_run-time m_run-time-outer">
            <span>{data?.videoDuration[0].text}</span>
          </div>
        )}
        {!!data?.video_duration?.length && (
          <div className="m_run-time m_run-time-outer">
            <span>{data?.video_duration[0].text}</span>
          </div>
        )}
        <div className="m_border"></div>
      </Link>
      {!showTitleInBox && (
        <span className="watch_item-title" style={{ color: titleColor }}>
          {titleCase(title)}
        </span>
      )}
    </div>
  );
}
