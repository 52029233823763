import { graphql, Link, navigate } from 'gatsby';
import React, { useContext } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import ArrowLeft from '../../images/icon-arrow-left.svg';
import WatchItem from '../../components/Watch/WatchItem';
import { AppContext } from '../../apollo/wrap-root-element';
import { normalizeWatchCategories } from '../../utils/watch';
import WatchTopItem from '../../components/Watch/WatchTopItem';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { getFormatedEpisodesCategoryTitle, season3SubCategories } from '../../utils/watch';

const WatchCategoryPageComponent = (props) => {
  const {
    data: { bgContact, totalWatchData, season1, season2, season3, placesBackgroundImage },
    pageContext: { categoryName, subCategoryName, isSubSectionRequired, season3SubcategoryName },
    location: { href },
  } = props;

  const categories = {
    'season-1': 'Season 1',
    'season-2': 'Season 2',
    'season-3': 'Season 3',
  };

  const backgroundToSeasonMap = {
    'season-1': season1,
    'season-2': season2,
    'season-3': season3,
  };
  const { selectedSeasonForTab, setSelectedSeason } = useContext(AppContext);

  const isBtsCategory = categoryName === 'bts' || categoryName?.startsWith('Behind the Scenes');
  const normalizedWatchData = normalizeWatchCategories(totalWatchData?.nodes || [], true, true, isBtsCategory);
  let requiredData = [];
  const selectedCategoryDetails = normalizedWatchData[categoryName];

  if (isSubSectionRequired) {
    requiredData = season3SubcategoryName
      ? selectedCategoryDetails[subCategoryName].filter((item) =>
          item.data.categories.some((item) => item.episodeCategory.categoryType === season3SubcategoryName)
        )
      : selectedCategoryDetails[subCategoryName];
  } else {
    requiredData = selectedCategoryDetails;
  }

  const title = isSubSectionRequired ? subCategoryName : categoryName;
  const titleWithNoHyphen = title?.replace(/-/g, ' ')?.toUpperCase() || '';

  if (!requiredData) {
    console.error('Required data is undefined.');

    return null;
  }

  const selectedSeason = isBtsCategory
    ? selectedSeasonForTab
    : requiredData[1]?.data?.categories[0]?.episodeCategory?.categoryType || null;
  const bgImage = backgroundToSeasonMap[selectedSeason] || bgContact;
  const placeBg = placesBackgroundImage.nodes.length ? placesBackgroundImage.nodes[0].data?.bgImage?.url : null;

  // First watch episode is shown as featured episode
  const [featuredWatchItem] = isBtsCategory ? requiredData[selectedSeasonForTab].slice(-1) : requiredData.splice(0, 1);

  const episodeSeasonSortMap = {
    'season-1': 1,
    'season-2': 2,
    'season-3': 3,
  };

  // Season 1 episodes should precede season 2.
  const orderedWatchEpisodes = isBtsCategory
    ? requiredData[selectedSeasonForTab]
    : requiredData?.sort((x, y) => {
        const xParam = x?.data?.categories[0]?.episodeCategory?.categoryType;
        const yParam = y?.data?.categories[0]?.episodeCategory?.categoryType;

        return episodeSeasonSortMap[xParam] - episodeSeasonSortMap[yParam];
      });

  const seoData = {
    canonical: href || '',
    title: titleWithNoHyphen,
    metaDesc: `Watch ${titleWithNoHyphen}`,
    opengraphDescription: `Watch ${titleWithNoHyphen}`,
    twitterDescription: `Watch ${titleWithNoHyphen}`,
    opengraphTitle: titleWithNoHyphen,
    twitterTitle: titleWithNoHyphen,
    opengraphImage: {
      sourceUrl: bgImage.childImageSharp.fluid.src,
    },
    twitterImage: {
      sourceUrl: bgImage.childImageSharp.fluid.src,
    },
    opengraphUrl: href || '',
  };

  const bgStyles = {
    backgroundImage: placeBg ? `url(${placeBg}) !important` : '',
  };

  return (
    <BodyClassName className="body-light page-watch page-watch--category">
      <DefaultLayoutComponent seo={seoData}>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgImage.childImageSharp.fluid}
            preserveStackingContext
            style={bgStyles}
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">
                  {season3SubcategoryName
                    ? season3SubCategories[season3SubcategoryName]
                    : getFormatedEpisodesCategoryTitle(title)}
                </h3>
                {isBtsCategory ? (
                  <div className="pb-content">
                    <div className="wrapper wrapper-md">
                      <p className="p-xxl">
                        What is it really like to film on location?
                        <br />
                        Watch and see the challenges, joys, struggles and realities of filming these episodes.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="wrapper wrapper-lg pd-x-sm la-pn">
                <div className="breadcrumb pd-t-0 bc-bg-transparent pd-x-sm watch-bc">
                  <div className="bc-holder" onClick={() => navigate('/watch')}>
                    <img src={ArrowLeft} alt="" />
                    <p>Back</p>
                  </div>
                  {isBtsCategory && (
                    <div className="tab-container">
                      <div className="filter-categories mg-b-0" style={{ display: 'flex', justifyContent: 'center' }}>
                        <ul className="fil_cat-list d-flex">
                          {selectedCategoryDetails &&
                            Object.keys(categories).map(
                              (item, index) =>
                                selectedCategoryDetails[item] && (
                                  <li
                                    key={`categoryName-${index}`}
                                    className={`fil_cat-item  mg-r-5 mg-b-5 ${
                                      item === selectedSeasonForTab ? 'active' : ''
                                    }`}
                                    onClick={() => setSelectedSeason(item)}
                                  >
                                    {categories[item]}
                                  </li>
                                )
                            )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                {!!featuredWatchItem && <WatchTopItem episodeDetail={featuredWatchItem} isCategoryPage={true} />}
              </div>
            </div>
          </BackgroundImage>

          <section className="categories-watch categories-filters bg-dark categories-watch--detail">
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="cat_watch-list">
                <div className="cat_watch-row">
                  <div className="cat_watch-body">
                    <div className="cat_watch-body-list">
                      <div className="cat_watch-item">
                        <div className="watch_item-row columns-5 d-flex">
                          {!!orderedWatchEpisodes.length &&
                            orderedWatchEpisodes.map((episodeItem, idx) => (
                              <WatchItem key={idx} data={episodeItem} className="cols watch_item-list " showPlayIcon />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query MyWatchCategoryPageQuery($subCategoryName: String = " ") {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    season1: file(relativePath: { eq: "season-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    season2: file(relativePath: { eq: "season-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    season3: file(relativePath: { eq: "season-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    placesBackgroundImage: allPrismicPlaces(filter: { uid: { eq: $subCategoryName } }) {
      nodes {
        data {
          bgImage: background_image {
            url
          }
        }
      }
    }

    totalWatchData: allPrismicEpisodes(
      filter: {
        data: {
          categories: {
            elemMatch: {
              episodes_category: {
                slug: {
                  in: [
                    "season-1"
                    "season-2"
                    "season-3"
                    "adverts"
                    "trailers"
                    "the-7-churches-of-revelation"
                    "360-tours"
                    "documentaries"
                    "locations-from-the-sky"
                    "the-great-controversy"
                  ]
                }
              }
            }
          }
          language: { eq: "English" }
        }
      }
      sort: { fields: data___priority, order: ASC }
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
            }
          }
          description {
            text
          }
          language
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          bts
          subPeriods: sub_period {
            subPeriod: sub_period1 {
              slug
            }
          }
          people {
            name: people1 {
              authorName: slug
            }
          }
          places {
            name: places1 {
              placeName: slug
            }
          }
          relatedArticles: related_articles {
            articles {
              slug
            }
          }
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
        uid
        url
      }
    }
  }
`;

export default WatchCategoryPageComponent;
